'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Top = void 0;
var $ = require("jquery");
require("slick-carousel");
var Top = /** @class */ (function () {
    function Top(
    // private ajaxing: boolean = false,
    ) {
        this.topNewsLength = 5;
        var self = this;
        if ($('#ThisIsTopPage').length > 0) {
            // slick
            $(".slider").slick({
                arrows: false,
                autoplay: true,
                speed: 800,
                autoplaySpeed: 8000,
                adaptiveHeight: true,
                variableWidth: true,
                centerMode: true,
            });
            // top news
            this.topNewsLength = Number($('#paramNarrowTopNewsLength').data('length'));
            var topNewss = $('.bulletTopNews');
            for (var i = 0; i < topNewss.length; i++) {
                var topNews = topNewss.eq(i);
                if (i >= this.topNewsLength) {
                    topNews.hide();
                }
            }
        }
    }
    return Top;
}());
exports.Top = Top;
exports.default = Top;
