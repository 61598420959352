'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
var $ = require("jquery");
var navi_1 = require("./class/navi");
var top_1 = require("./class/top");
var _anchor_1 = require("./class/_anchor");
$(function () {
    new navi_1.default;
    new top_1.default;
    new _anchor_1.default();
});
