'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Anchor = void 0;
var $ = require("jquery");
var Anchor = /** @class */ (function () {
    function Anchor() {
        var _this = this;
        this.scrollSpeed = 500;
        this.scrollEasing = "swing";
        var urlHash = location.hash;
        if (urlHash) {
            $('body, html').stop().scrollTop(0);
            setTimeout(function () {
                var position = $(urlHash).offset().top;
                var headerHeight = _this.getHeaderHeight();
                // 移動実行
                $('body, html').animate({ scrollTop: position - headerHeight }, _this.scrollSpeed);
            }, 100);
        }
        var self = this;
        $('a[href^="#"]').click(function () {
            var href = $(this).attr("href");
            var headerHeight = self.getHeaderHeight();
            var position = $(href).offset().top - headerHeight;
            $('body,html').stop().animate({ scrollTop: position }, 600); // スクロールの速さ
        });
    }
    //
    Anchor.prototype.getHeaderHeight = function () {
        var headerHeight = 0;
        if ($('.paramPcHeader').is(':visible')) {
            headerHeight = $('.paramPcHeader').outerHeight();
        }
        else {
            headerHeight = $('.paramSpHeader').outerHeight();
        }
        return headerHeight;
    };
    return Anchor;
}());
exports.Anchor = Anchor;
exports.default = Anchor;
