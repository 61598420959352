'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Navi = void 0;
var $ = require("jquery");
var Navi = /** @class */ (function () {
    // private ext: string = 'jpg';
    function Navi(
    // private ajaxing: boolean = false,
    ) {
        var self = this;
        $('.trigOpenNavi').on('mouseover', function () {
            var menu = $(this).data('menu');
            $('.bulletOpenNavi').hide();
            $('.bulletOpenNavi[data-menu="' + menu + '"]').show();
            $('#bulletNavi').css({ height: "100vh" });
        });
        $('#trigCloseNavi').on('mouseleave', function () {
            $('#bulletNavi').css({ height: 0 });
        });
        $('#toggleNaviSp').click(function () {
            $('#bulletNaviSp').toggleClass('open');
        });
    }
    return Navi;
}());
exports.Navi = Navi;
exports.default = Navi;
